import { resourceEnum } from '@/enumerators/resource-types-map.js';

import { getFileTypeFromExt } from '@/enumerators/file-type.js';
import { generateEditAddLocalFileItem } from '@/components/AddLocalFile/generate-data.js';

const resourceResReqMap = {
  data_type: {
    reqKey: 'type',
    default: ''
  },
  source_id: {
    reqKey: 'list',
    default: () => []
  },
  title: {
    reqKey: 'base_name',
    default: ''
  },
};
const moduleResReqMap = {
  title: {
    reqKey: 'title',
    default: '本模块名称'
  },
  type_source_list: {
    reqKey: 'data',
    default: () => []
  }
};
const chapterModuleResReqMap = {
  title: {
    reqKey: 'title',
    default: '本模块名称'
  },
  chapter_type_source_list: {
    reqKey: 'data',
    default: () => []
  }
};
const sectionResReqMap = {
  title: {
    reqKey: 'section_title',
    default: '本节名称'
  },
  material_section_type_list: {
    reqKey: 'model',
    default: () => []
  }
};
const chapterResReqMap = {
  title: {
    reqKey: 'title',
    default: '本章名称'
  },
  material_chapter_type_list: {
    reqKey: 'model',
    default: () => []
  },
  section_list: {
    reqKey: 'section',
    default: () => []
  }
};

export function generateResourceItem(resourceData = {}, title) {
  try {

  const tempObj = {};
  Object.keys(resourceResReqMap).forEach(key => {
    const defaultValue = resourceResReqMap[key].default;
    switch (key) {
      case 'source_id':
        switch (Number(resourceData.data_type)) {
          case resourceEnum.TEACHING_RESOURCE_TYPE:
          case resourceEnum.EXERCISES_RESOURCE_TYPE:
            tempObj[key] = resourceData[key].split(',')
            break;
        }
        break;
      case 'title':
        switch (Number(resourceData.data_type)) {
          case resourceEnum.TEACHING_RESOURCE_TYPE:
            tempObj[key] =
              (resourceData.source_info && resourceData.source_info.title) ||
              (resourceData.list && resourceData.list.title) ||
              title ||
              defaultValue;
            break;
          case resourceEnum.EXERCISES_RESOURCE_TYPE:
            tempObj[key] = resourceData.base_name || title || defaultValue;
            break;
        }
        break;
      default:
        tempObj[key] = resourceData[key] || defaultValue;
    }
  });
  if (Number(resourceData.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE) {
    tempObj.typeStatus = resourceData.typeStatus || Number(resourceData.source_info[0].type_status);
  }
  return tempObj;

  } catch (e) {
    console.log(e);
    return null;
  }
}
export function generateModuleItem(moduleData = {}, type = 'section') {
  const tempObj = {};
  let _muduleResReqMap;
  switch (type) {
    case 'chapter':
      _muduleResReqMap = chapterModuleResReqMap;
      break;
    default:
      _muduleResReqMap = moduleResReqMap;
  }
  Object.keys(_muduleResReqMap).forEach(key => {
    const defaultValue = _muduleResReqMap[key].default;
    switch (key) {
      case 'chapter_type_source_list':
      case 'type_source_list':
        if (Array.isArray(moduleData[key]) && moduleData[key].length > 0) {
          const tempFileList = [];
          const tempList = [];
          
          moduleData[key].forEach(resourceData => {
            switch (Number(resourceData.data_type)) {
              case 3: // 附件
                tempFileList.push(generateEditAddLocalFileItem(resourceData.source_info));
                break;
              default:
                const generatedResourceItem = generateResourceItem(resourceData);
                generatedResourceItem && tempList.push(generateResourceItem(resourceData));
            }
          });

          /*
          let exerciseResourceIndex = 1;
          tempList.forEach(item => {
            switch (Number(item.data_type)) {
              case resourceEnum.EXERCISES_RESOURCE_TYPE:
                item.title = `习题${exerciseResourceIndex++}`;
                break;
            }
          });
          */

          tempObj.fileList = tempFileList;

          tempObj[key] = tempList;
        } else {
          tempObj[key] = defaultValue();
        }
        break;
      default:
        tempObj[key] = moduleData[key] || defaultValue;
    }
  });
  return tempObj;
}
export function generateSectionItem(sectionData = {}, sectionIndex = Date.now()) {
  const tempObj = {
    key: `2-${sectionIndex}-${Date.now()}`,
    readOnly: !!sectionData.readOnly
  };
  Object.keys(sectionResReqMap).forEach(key => {
    const defaultValue = sectionResReqMap[key].default;
    switch (key) {
      case 'material_section_type_list':
        if (Array.isArray(sectionData[key]) && sectionData[key].length > 0) {
          tempObj[key] = sectionData[key].map(moduleData => generateModuleItem(moduleData, 'section'));
        } else {
          tempObj[key] = defaultValue();
          tempObj[key].push(
            generateModuleItem({ title: '教材' }, 'section'),
            generateModuleItem({ title: '课件' }, 'section'),
            generateModuleItem({ title: '微课' }, 'section'),
            generateModuleItem({ title: '实训' }, 'section'),
            generateModuleItem({ title: '习题' }, 'section')
          );
        }
        break;
      default:
        tempObj[key] = sectionData[key] || defaultValue;
    }
  });
  return tempObj;
}
export function generateChapterItem(chapterData = {}, chapterIndex = Date.now()) {
  const tempObj = {
    key: `1-${chapterIndex}-${Date.now()}`,
    readOnly: !!chapterData.readOnly
  };
  Object.keys(chapterResReqMap).forEach(key => {
    const defaultValue = chapterResReqMap[key].default;
    switch (key) {
      case 'material_chapter_type_list':
        if (Array.isArray(chapterData[key]) && chapterData[key].length > 0) {
          tempObj[key] = chapterData[key].map(moduleData => generateModuleItem(moduleData, 'chapter'));
        } else {
          tempObj[key] = defaultValue();
          tempObj[key].push(
            generateModuleItem({ title: '教材' }, 'chapter'),
            generateModuleItem({ title: '课件' }, 'chapter'),
            generateModuleItem({ title: '微课' }, 'chapter'),
            generateModuleItem({ title: '实训' }, 'chapter'),
            generateModuleItem({ title: '习题' }, 'chapter')
          );
        }
        break;
      case 'section_list':
        if (Array.isArray(chapterData[key]) && chapterData[key].length > 0) {
          tempObj[key] = chapterData[key].map((sectionData, sectionIndex) => generateSectionItem(sectionData, `${chapterIndex}${sectionIndex}`));
        } else {
          tempObj[key] = defaultValue();
          tempObj[key].push(generateSectionItem());
        }
        break;
      default:
        tempObj[key] = chapterData[key] || defaultValue;
    }
  });
  return tempObj;
}

function getModuleItem(
  moduleData,
  moduleIndex,
  usedModuleResReqMap,
  usedResourceResReqMap,
  resReqMapType = 'material'
) {
  const tempModule = {};
  Object.keys(usedModuleResReqMap).map(moduleKey => {
    switch (moduleKey) {
      case 'chapter_type_source_list':
      case 'type_source_list':
        tempModule[usedModuleResReqMap[moduleKey].reqKey] = moduleData[moduleKey].map(resource => {
          const tempResource = {};
          Object.keys(usedResourceResReqMap).forEach(resourceKey => {
            switch (resourceKey) {
              case 'source_id':
                switch (Number(resource.data_type)) {
                  case resourceEnum.TEACHING_RESOURCE_TYPE:
                    tempResource[usedResourceResReqMap[resourceKey].reqKey] = resource[resourceKey].join(',');
                    break;
                  case resourceEnum.EXERCISES_RESOURCE_TYPE:
                    // tempResource[usedResourceResReqMap[resourceKey].reqKey] = [...resource[resourceKey]];
                    tempResource[usedResourceResReqMap[resourceKey].reqKey] = resource[resourceKey].join(',');
                    break;
                }
                break;
              default:
                tempResource[usedResourceResReqMap[resourceKey].reqKey] = resource[resourceKey];
            }
          });
          return tempResource;
        });
        break;
      default:
        tempModule[usedModuleResReqMap[moduleKey].reqKey] = moduleData[moduleKey];
    }
  });
  switch (resReqMapType) {
    case 'course':
      tempModule.sort = moduleIndex + 1;
      break;
  }

  moduleData.fileList && (tempModule.fileList = moduleData.fileList);

  return tempModule;
}
export function getChapters(srcData, resReqMapType = 'material') {
  let usedChapterResReqMap;
  let usedSectionResReqMap;
  let usedChapterModuleResReqMap;
  let usedSectionModuleResReqMap;
  let usedResourceResReqMap;
  switch (resReqMapType) {
    case 'material':
      usedChapterResReqMap = chapterResReqMap;
      usedSectionResReqMap = sectionResReqMap;
      usedChapterModuleResReqMap = chapterModuleResReqMap;
      usedSectionModuleResReqMap = moduleResReqMap;
      usedResourceResReqMap = resourceResReqMap;
      break;
    case 'course':
      usedChapterResReqMap = {
        title: {
          reqKey: 'chapter_title'
        },
        material_chapter_type_list: {
          reqKey: 'model_list'
        },
        section_list: {
          reqKey: 'section_list'
        }
      };
      usedSectionResReqMap = {
        title: {
          reqKey: 'section_title'
        },
        material_section_type_list: {
          reqKey: 'model_list'
        }
      };
      usedChapterModuleResReqMap = {
        title: {
          reqKey: 'title'
        },
        chapter_type_source_list: {
          reqKey: 'list'
        }
      };
      usedSectionModuleResReqMap = {
        title: {
          reqKey: 'title'
        },
        type_source_list: {
          reqKey: 'list'
        }
      };
      usedResourceResReqMap = {
        data_type: {
          reqKey: 'type'
        },
        source_id: {
          reqKey: 'ids'
        },
        title: {
          reqKey: 'base_name'
        },
      };
      break;
  }

  const tempChapters = [];
  srcData.forEach((chapter, chapterIndex) => {
    if (chapter.readOnly) {
      return;
    }
    const tempChapter = {};
    Object.keys(usedChapterResReqMap).forEach(chapterKey => {
      switch (chapterKey) {
        case 'material_chapter_type_list': // 章 - 模块列表
          tempChapter[usedChapterResReqMap[chapterKey].reqKey] = chapter[chapterKey].map((moduleData, moduleIndex) => {
            return getModuleItem(
              moduleData,
              moduleIndex,
              usedChapterModuleResReqMap,
              usedResourceResReqMap,
              resReqMapType
            );
          });
          break;
        case 'section_list':
          tempChapter[usedChapterResReqMap[chapterKey].reqKey] = chapter[chapterKey].map((section, sectionIndex) => {
            const tempSection = {};
            Object.keys(usedSectionResReqMap).forEach(sectionKey => {
              switch (sectionKey) {
                case 'material_section_type_list':
                  tempSection[usedSectionResReqMap[sectionKey].reqKey] = section[sectionKey].map((moduleData, moduleIndex) => {
                    return getModuleItem(
                      moduleData,
                      moduleIndex,
                      usedSectionModuleResReqMap,
                      usedResourceResReqMap,
                      resReqMapType
                    );
                  });
                  break;
                default:
                  tempSection[usedSectionResReqMap[sectionKey].reqKey] = section[sectionKey];
              }
            });
            switch (resReqMapType) {
              case 'course':
                tempSection.sort = sectionIndex + 1;
                break;
            }
            return tempSection;
          });
          break;
        default:
          tempChapter[usedChapterResReqMap[chapterKey].reqKey] = chapter[chapterKey];
      }
    });
    switch (resReqMapType) {
      case 'course':
        tempChapter.sort = chapterIndex + 1;
        break;
    }
    tempChapters.push(tempChapter);
  });

  return tempChapters;
}