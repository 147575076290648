<template>
  <NDropdown
    show-arrow
    placement="right-start"
    trigger="click"
    :options="options"
    :render-label="labelRender"
    @select="handleSelect"
  >
    <NButton
      size="small"
      style="margin-bottom: 8px;"
    >教辅材料</NButton>
  </NDropdown>
</template>

<script setup>
  import { h, computed } from 'vue';
  import { NDropdown, NButton, useMessage } from 'naive-ui';
  import { saveAs } from 'file-saver'

  import themeSettings from '@/settings/theme-settings.js';

  import { correctURL } from '@/utils/url.js';

  const props = defineProps({
    materialArr: {
      type: Array,
      default: function () { return []; }
    }
  });

  const message = useMessage();

  function downFile(fileURL, fileName) {
    message.success('正在下载文件');
    saveAs(correctURL('file', fileURL), fileName);
  }

  const options = computed(() => {
    return [
      {
        type: 'group',
        label: '教辅材料列表',
        key: 'title',
        children: props.materialArr.map((item, index) => ({
          label: item.file_name,
          key: String(index),
          materialData: item
        }))
      }
    ]
  });
  function labelRender(option) {
    if (option.type === 'group') {
      return h('div', { style: 'padding: 0 10px 8px; font-size: 15px; font-weight: bold; color: #333; text-align: center;' }, option.label);
    } else {
      const labelEls = [
        h('div', { title: option.label, style: 'white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;' }, option.label)
      ];
      labelEls.push(
        h(
          'a',
          {
            href: 'javascript:;',
            style: `position: absolute; top: 0; right: 10px; color: ${themeSettings.appThemeColor}`,
            onClick: () => { downFile(option.materialData.file_url, option.label) }
          },
          '下载'
        )
      );
      return h(
        'div',
        { style: 'position: relative; width: 285px; padding: 0 65px 0 0; cursor: default;' },
        labelEls
      );
    }
  };
  function handleSelect(key, { resourceData }) {
    // emit('current-resource-change', resourceData);

    // currentPicIndex.value = 0;
  };
</script>